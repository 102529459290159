import { useQuery } from '@tanstack/react-query'
import { getBrandConfig } from '@/config/config'
import { useFetch, useOptions } from '@/use-fetch'

export interface GameConfig {
  volatility?: 'HIGH' | 'MEDIUM' | 'LOW'
  minPlay?: number // 0.25,
  rtp: number // 'double[0.00, 100.00]' 97.5
}

export interface GameCardData {
  gameId: string
  name: string
  description?: string
  launchUrl: string
  isFavorite: boolean
  config: GameConfig
  jackpots: {
    name: string // Grand
    value: string // "123321123"
  }[]
  similarGames: {
    gameId: string
    imageUrl: string
    name: string
  }[]
}

function useGameInfo(gameId: string) {
  const config = getBrandConfig()
  const options = useOptions()
  const { get } = useFetch()

  const { data, isLoading, error } = useQuery({
    queryKey: [config.gameApi.url, '/game', gameId, options],
    queryFn: () =>
      get<GameCardData>(config.gameApi.url + '/' + gameId, options),
  })

  return {
    isLoading,
    data,
    error,
  }
}

export { useGameInfo }
