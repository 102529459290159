import { cva, VariantProps } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import { IconNames } from '@vgw/multibrand-fe-icon'
import * as Tabs from '@radix-ui/react-tabs'
import { useState } from 'react'
import { useLingui } from '@lingui/react/macro'

const navbarVariants = cva(
  'z-1 bg-navigation-surface text-navigation-content-base data-[state=active]:text-content-base sticky px-4 pb-2 pt-3 backdrop-blur-sm lg:hidden',
  {
    variants: {
      variant: {
        // Full-width
        primary: 'bottom-0 inline-flex w-full space-x-px',
        // Full-width Highlighted
        secondary: 'bottom-0 inline-flex w-full space-x-px',
        // Rounded Partial
        tertiary:
          'bottom-2 inline-flex w-[95%] space-x-px rounded-full shadow-[0_2px_0px_0px_rgba(23,25,35,0.25)]',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
)

export interface MobileNavbarProps extends VariantProps<typeof navbarVariants> {
  className?: string
}

const MobileNavbar = ({ variant, className }: MobileNavbarProps) => {
  const { t } = useLingui()
  const [tab, setTab] = useState('')
  const navbarVariant = variant ?? ''
  return (
    <Tabs.Root
      data-testid="mobile-navbar"
      className={cn(navbarVariants({ variant, className }))}
      defaultValue="home"
      onValueChange={(value: string) => {
        setTab(value)
      }}
      value={tab}
    >
      <Tabs.List aria-label={t`Mobile Nav Menu`} className="flex w-full">
        {/* Home Button */}
        <NavItem
          value="home"
          label={t`Home`}
          icon="home-filled"
          navbarVariant={navbarVariant}
        />
        {/* Search Button */}
        <NavItem
          value="browse"
          label={t`Browse`}
          icon="search"
          navbarVariant={navbarVariant}
        />
        {/* Store Button */}
        <NavItem
          value="store"
          label={t`Store`}
          icon="present"
          navbarVariant={navbarVariant}
        />
        {/* Hamburger Button */}
        <NavItem
          value="menu"
          label={t`Menu`}
          icon="hamburger-menu"
          navbarVariant={navbarVariant}
        />
      </Tabs.List>
    </Tabs.Root>
  )
}

interface NavItemProps {
  value: string
  label: string
  icon: IconNames
  navbarVariant: string
}

const NavItem = ({ value, label, icon, navbarVariant }: NavItemProps) => {
  // including 'group' as a className to link with the svg element in 'IconLabel > custom-icon > svg'
  return (
    <Tabs.Trigger
      data-testid={`nav-${value}-item`}
      className="data-[state=active]:text-content-base group flex h-auto w-full flex-col items-center justify-center gap-0.5"
      value={value}
    >
      <div className="flex flex-col items-center gap-1">
        <div
          className={
            navbarVariant === 'secondary'
              ? 'group-data-[state=active]:bg-navigation-content-base flex w-full justify-center rounded-full py-1'
              : 'py-1'
          }
        >
          <CustomIcon
            name={icon}
            size="md"
            title={label}
            fill="fill-navigation-content-base"
            className="group-data-[state=active]:fill-content-base"
          />
        </div>
        {label}
      </div>
    </Tabs.Trigger>
  )
}

export default MobileNavbar
