import * as Popover from '@radix-ui/react-popover'
import { cn } from '@vgw/tailwind-merger'
import { IconButton } from '@vgw/multibrand-fe-icon-button'

interface DescriptionPopoverProps {
  accessibilityLabel: string
  content: string
}

export const DescriptionPopover = ({
  accessibilityLabel,
  content,
}: DescriptionPopoverProps) => (
  <Popover.Root>
    <Popover.Trigger asChild>
      <IconButton
        icon="info-circled"
        variant="ghost"
        size="sm"
        aria-label={accessibilityLabel}
      />
    </Popover.Trigger>
    <Popover.Portal>
      <Popover.Content
        className={cn([
          'relative',
          `w-[218px]`,
          'px-4',
          'py-3',

          'rounded-lg',
          'border',
          'border-tooltip-border',

          'bg-tooltip-surface',

          'text-sm',
          'text-tooltip-content',
          'text-center',

          'will-change-[opacity,transform]',
          'ease-popover-bezier',
          'data-[state=open]:animate-slide-up-and-fade',

          'outline-offset-2',
        ])}
        sideOffset={10}
      >
        <span
          className={cn([
            'absolute',
            `left-[calc(50%-12px)]`,
            `top-[-12px]`,

            'h-0',
            'w-0',

            `border-b-[12px]`,
            `border-l-[12px]`,
            `border-r-[12px]`,
            'border-b-tooltip-border',
            'border-l-[transparent]',
            'border-r-[transparent]',

            'before:absolute',
            `before:left-[calc(50%-11px)]`,
            'before:top-[0.1rem]',

            'before:h-0',
            'before:w-0',

            `before:border-b-[11px]`,
            `before:border-l-[11px]`,
            `before:border-r-[11px]`,
            'before:border-b-tooltip-surface',
            'before:border-l-[transparent]',
            'before:border-r-[transparent]',
          ])}
        />
        <p>{content}</p>
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
)
