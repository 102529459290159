import { CustomIcon } from '@vgw/multibrand-fe-icon'
import { CoinType } from '@vgw/multibrand-fe-coin-type-toggle'
import { CoinTypeAmounts } from '../../types'
import { useCoinAmount } from './hooks/use-coin-amount'

export interface CoinAmountProps {
  amount?: number
  coinType: CoinType
  coinAmounts?: CoinTypeAmounts
}

export const CoinAmount = ({ coinType, amount }: CoinAmountProps) => {
  const { iconName, iconTitle, coinsFormatter } = useCoinAmount(coinType)

  return (
    <div
      data-testid={`${coinType}-value`}
      className="flex w-fit rounded-3xl bg-surface-neutral px-2 py-1"
    >
      <CustomIcon
        name={iconName}
        size="md"
        className="mr-1"
        wrapperClassName="mr-1"
        title={iconTitle}
      />
      {amount !== undefined && (
        <p className="text-sm font-medium leading-5 tracking-normal text-content-base">
          {coinsFormatter(amount)}
        </p>
      )}
    </div>
  )
}
