import Logo from '@/features/header/logo'
import { Button } from '@vgw/multibrand-fe-button'
import { cn } from '@vgw/tailwind-merger'
import { Link } from 'react-router-dom'
import { useTracking } from '@vgw/multibrand-fe-tracking'
import { UserHeader, UserHeaderProps } from './user-header/user-header'
import { Trans } from '@lingui/react/macro'

interface UserProps {
  login: () => Promise<void>
  logout: () => Promise<void>
  userName?: string
  userEmail?: string
  isUserLoggedIn: boolean
}

export interface HeaderProps {
  user: UserProps
  className: string
  coinTypeInfo: UserHeaderProps['coinTypeInfo']
}

const Header = ({
  user: { isUserLoggedIn, userName, logout, login, userEmail },
  className,
  coinTypeInfo,
}: HeaderProps) => {
  const { track } = useTracking()

  return (
    <header
      className={cn(
        'z-10 flex w-full justify-center bg-surface-base',
        className,
      )}
      data-testid="page-header"
    >
      <div className="flex w-full max-w-screen-2xl items-center justify-between px-2 py-2.5 sm:px-4 md:px-6">
        <Link data-testid="logo" to="/">
          <Logo />
        </Link>
        {!isUserLoggedIn && (
          <Button
            variant="primary"
            onClick={() => {
              track('Header:LoginStarted', { sendImmediate: true })
              void login() // Log in is based on redirects, that's why we don't care about the Promise here
            }}
          >
            <Trans>Sign In</Trans>
          </Button>
        )}

        {isUserLoggedIn && (
          <UserHeader
            logout={logout}
            username={userName}
            userEmail={userEmail}
            coinTypeInfo={coinTypeInfo}
          />
        )}
      </div>
    </header>
  )
}

export default Header
