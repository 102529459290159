import { type GameConfig as GameConfigData } from '@/features/game-api/use-game-info'
import { Volatility } from './volatility'
import { Feature } from './feature'
import { MinimalPlayAmount } from './minimal-play-amount'
import { CoinType } from '@/selected-coin-type-context'
import { PlayerReturn } from './player-return'

interface GameConfigProps {
  gameConfig: GameConfigData
  selectedCoinType: CoinType
}

export const GameConfig = ({
  gameConfig,
  selectedCoinType,
}: GameConfigProps) => {
  return (
    <div className="flex items-start justify-between self-stretch md:justify-start md:gap-8">
      <Feature isVisible={!!gameConfig.volatility}>
        <Volatility level={gameConfig.volatility!} />
      </Feature>
      <Feature isVisible={!!gameConfig.minPlay}>
        <MinimalPlayAmount
          minPlay={gameConfig.minPlay}
          selectedCoinType={selectedCoinType}
        />
      </Feature>
      <Feature>
        <PlayerReturn amount={gameConfig.rtp} />
      </Feature>
    </div>
  )
}
