import Header from '@/features/header/header'
import Footer from '@/features/footer/footer'
import { Outlet } from 'react-router-dom'
import { useOidc } from '@/providers/oidc'
import { useCoinType } from '@/layout/use-coin-type'
import { useContext } from 'react'
import { SelectedCoinTypeContext } from '@/selected-coin-type-context'
import { GAME_PLAY_PATH } from '@/config/paths'
import MobileNavbar from '@vgw/multibrand-fe-mobile-navbar'
import { useBrandFeatures } from '@/lib/use-brand-features'
import { ScrollRestoration } from 'react-router-dom'

interface RootProps {
  mainClassName?: string
  renderHeader?: boolean
  renderFooter?: boolean
  renderBackground?: boolean
}

export const Root = ({
  mainClassName,
  renderHeader = true,
  renderFooter = true,
  renderBackground = false,
}: RootProps) => {
  const { isUserLoggedIn, login, logout, oidcTokens } = useOidc()
  const { data, error, isLoading } = useCoinType(isUserLoggedIn)
  const { selectedCoinType, setSelectedCoinType } = useContext(
    SelectedCoinTypeContext,
  )
  const { isSweepsCoinsEnabled } = useBrandFeatures()

  return (
    <div className="flex min-h-screen w-full flex-col items-center bg-surface-base">
      <ScrollRestoration />
      {renderHeader && (
        <Header
          user={{
            userName: oidcTokens?.decodedIdToken.name as string,
            // TODO: replace with email from token,
            // for now it is preffered_username which equals to email in some cases
            userEmail: oidcTokens?.decodedIdToken.preferred_username as string,
            isUserLoggedIn,
            login: async () =>
              login && login({ doesCurrentHrefRequiresAuth: false }),
            logout: async () => logout && logout({ redirectTo: 'home' }),
          }}
          coinTypeInfo={{
            amounts: data,
            error,
            isLoading,
            selectedCoinType: selectedCoinType,
            gamePlayPath: GAME_PLAY_PATH,
            onCoinTypeChange: () =>
              setSelectedCoinType(selectedCoinType === 'GC' ? 'SC' : 'GC'),
            isSweepsCoinsEnabled,
          }}
          className="sticky inset-x-0 top-0"
        />
      )}
      <main
        className={[
          'flex w-full flex-1 flex-col items-center bg-surface-secondary',
          renderBackground
            ? 'bg-page-small-background bg-contain bg-top bg-no-repeat'
            : '',
          mainClassName,
        ].join(' ')}
      >
        <div
          className={[
            'flex w-full max-w-screen-2xl items-center',
            renderBackground ? 'grow flex-col' : '',
          ].join(' ')}
        >
          <Outlet />
        </div>
      </main>
      {renderFooter && <Footer isSweepsCoinsEnabled={isSweepsCoinsEnabled} />}
      <MobileNavbar />
    </div>
  )
}

export default Root
