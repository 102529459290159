import { AutoPlay, Carousel } from '@vgw/multibrand-fe-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { cn } from '@vgw/tailwind-merger'
import { useCallback, useEffect, useState } from 'react'
import { useLingui } from '@lingui/react/macro'

interface OverlayedHeroBannerCard {
  src: string
  title: string
  url?: string
  sizes?: string
  srcSet?: string
  thumbnailSrc: string
  onCardClick?: () => void
}

interface OverlayedHeroBannerProps {
  cards: OverlayedHeroBannerCard[]
  onCardEntersViewport?: (cardIndex: number) => void
  /**
   * Set the autoplay speed. Set to false if you want to disable autoplay completely.
   */
  autoPlayDelay?: number | false
}

export const OverlayedHeroBanner = ({
  cards,
  onCardEntersViewport,
  autoPlayDelay = 7000,
}: OverlayedHeroBannerProps) => {
  const { t } = useLingui()

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel({}, [
    ...(autoPlayDelay !== false
      ? [
          AutoPlay({
            rootNode: (emblaRoot: HTMLElement) =>
              emblaRoot.closest('.js-carousel-container'),
            playOnInit: true,
            delay: autoPlayDelay,
            stopOnMouseEnter: true,
            stopOnInteraction: false,
          }),
        ]
      : []),
  ])

  const onThumbClick = (index: number) => {
    if (!emblaMainApi) return
    emblaMainApi.scrollTo(index)
  }

  const onSelect = useCallback(() => {
    if (!emblaMainApi) return
    setSelectedIndex(emblaMainApi.selectedScrollSnap())
    onCardEntersViewport?.(emblaMainApi.selectedScrollSnap())
  }, [emblaMainApi, setSelectedIndex, onCardEntersViewport])

  useEffect(() => {
    if (!emblaMainApi) return
    onSelect()

    emblaMainApi.on('select', onSelect).on('reInit', onSelect)

    return () => {
      emblaMainApi.off('select', onSelect).off('reInit', onSelect)
    }
  }, [emblaMainApi, onSelect])

  const Img = ({ card }: { card: OverlayedHeroBannerCard }) => (
    <img
      alt={card.url ? t`Navigate to ${card.title}` : card.title}
      src={card.src}
      sizes={card.sizes}
      srcSet={card.srcSet}
      className="aspect-video h-full w-full object-cover"
    />
  )

  return (
    <Carousel className="js-carousel-container relative flex items-center pb-[67px] sm:pb-[74px] md:pb-[102px] lg:pb-[132px] xl:pb-0 xl:pr-[92px] 2xl:pr-[140px]">
      <Carousel.Container ref={emblaMainRef}>
        <Carousel.CardContainer>
          {cards.map((card, index) => (
            <Carousel.Card
              key={card.src + index}
              index={index}
              className="flex w-full items-center"
              data-testid={`card-${index}`}
            >
              {card.url ? (
                <a
                  href={card.url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => card.onCardClick?.()}
                  className="w-full cursor-pointer"
                >
                  {Img({ card })}
                </a>
              ) : (
                Img({ card })
              )}
            </Carousel.Card>
          ))}
        </Carousel.CardContainer>
      </Carousel.Container>
      <div className="absolute bottom-[22px] left-0 right-0 flex items-center justify-center gap-3 md:gap-4 xl:bottom-auto xl:left-auto xl:right-[22px] xl:flex-col">
        {cards.map((card, index) => (
          <button
            key={card.thumbnailSrc + index}
            className="h-[67px] w-[67px] sm:h-[74px] sm:w-[74px] md:h-[102px] md:w-[102px] lg:h-[132px] lg:w-[132px] xl:h-[92px] xl:w-[92px] 2xl:h-[140px] 2xl:w-[140px]"
            data-testid="overlayed-hero-banner-thumbnail-button"
            onClick={() => onThumbClick(index)}
          >
            <img
              alt={t`Select ${card.title}`}
              src={card.thumbnailSrc}
              className={cn('rounded-radius-base h-full w-full object-cover', {
                'outline outline-2 outline-white': selectedIndex === index,
              })}
            />
          </button>
        ))}
      </div>
    </Carousel>
  )
}
