import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getBrandConfig } from '@/config/config'
import { useFetch, useOptions } from '@/use-fetch'

interface GameFavorite {
  gameId: string
  isFavorite: boolean
}

function useGameFavorite() {
  const config = getBrandConfig()
  const options = useOptions()
  const { post, del } = useFetch()
  const queryClient = useQueryClient()

  const { error, data, isPending, mutate } = useMutation({
    mutationFn: ({ gameId, isFavorite }: GameFavorite) =>
      isFavorite
        ? del(`${config.favoriteApi.url}/${gameId}`)
        : post(`${config.favoriteApi.url}/${gameId}`),

    onSettled: async (_data, _error, { gameId }) => {
      return await queryClient.invalidateQueries({
        queryKey: [config.gameApi.url, '/game', gameId, options],
      })
    },
  })

  return {
    toggleFavorite: mutate,
    isLoading: isPending,
    error,
    data,
  }
}

export { useGameFavorite }
