import { useQuery } from '@tanstack/react-query'
import { getBrandConfig } from '@/config/config'
import { GetOptions, useFetch } from '@/use-fetch'
import { useContext } from 'react'
import { useOidc } from '@/providers/oidc'
import { SelectedCoinTypeContext } from '@/selected-coin-type-context'

/*
  TODO: refactor
  Remove redundant useOptions (duplicated code) and use the same function from use-fetch.
  Workaround for now because of an error: Config needs to be loaded before it can be used.
*/
function useOptions(): GetOptions | undefined {
  const { isUserLoggedIn } = useOidc()
  const { selectedCoinType } = useContext(SelectedCoinTypeContext)

  if (!isUserLoggedIn) return

  return {
    queryParams: {
      coinType: selectedCoinType,
    },
  }
}

interface GameResponse {
  gameId: string
  name: string
  index: number
  launchUrl: string
  jackPotValue?: number
  thumbnails: {
    image1x1: string
    image2x3: string
    image16x9: string
  }
}

type GameCategoryLayout = 'SQUARE' | 'PORTRAIT' | 'LANDSCAPE'

interface GameCategoryResponse {
  name: string
  index: number
  layout: GameCategoryLayout
  games: GameResponse[]
  iconUrl?: string
  description?: string
}

export interface GameGalleryResponse {
  coinMode: 'GC_ONLY' | 'GC_SC'
  categories: GameCategoryResponse[]
}

const mapper = (res: GameGalleryResponse) => ({
  coinMode: res.coinMode,
  categories: res.categories.map((category) => ({
    name: category.name,
    index: category.index,
    variant:
      category.layout.toLocaleLowerCase() as Lowercase<GameCategoryLayout>,
    iconUrl: category.iconUrl,
    description: category.description,
    games: category.games.map((game) => ({
      name: game.name,
      index: game.index,
      gameId: game.gameId,
      launchUrl: game.launchUrl,
      thumbnails: game.thumbnails,
    })),
  })),
})

export type Game = GameCategory['games'][number]
export type GameCategory = GameCategoriesQueryData['categories'][number]
export interface GameCategoriesQueryData extends ReturnType<typeof mapper> {}

function useGallery() {
  const config = getBrandConfig()
  const options = useOptions()
  const { get } = useFetch()

  const { data, isLoading, error } = useQuery({
    queryKey: ['/gallery', config.gapApi.url, options],
    queryFn: () =>
      get<GameGalleryResponse>(config.gapApi.url, options).then(mapper),
  })

  return {
    isLoading: isLoading,
    data: data,
    error,
  }
}

export { useGallery }
