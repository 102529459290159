import { useCallback, useEffect, useState } from 'react'
import {
  automaticallyShowInAppMessages,
  changeUser,
  getCachedContentCards,
  initialize,
  openSession,
  removeSubscription,
  requestContentCardsRefresh,
  subscribeToContentCardsUpdates,
} from '@braze/web-sdk'
import { BrazeCardWithExtras } from '@/features/braze/types'
import { getBrandConfig } from '@/config/config'
import { useOidc } from '@/providers/oidc'

type StringValues<T> = {
  [K in keyof T]?: string
}

export const useBraze = <Extras extends StringValues<Extras>>() => {
  const { braze } = getBrandConfig()
  const { oidcTokens, isUserLoggedIn } = useOidc()

  const [brazeBannerContentCards, setBrazeBannerContentCards] = useState<{
    isLoading: boolean
    cards: BrazeCardWithExtras<Extras>[] | undefined
  }>({
    isLoading: true,
    cards: undefined,
  })

  const setCards = useCallback(() => {
    setBrazeBannerContentCards({
      isLoading: false,
      cards: getCachedContentCards().cards as BrazeCardWithExtras<Extras>[],
    })
  }, [])

  useEffect(() => {
    initialize(braze.apiKey, {
      baseUrl: braze.baseUrl,
      enableLogging: true,
    })

    if (isUserLoggedIn) {
      changeUser(oidcTokens?.decodedIdToken.preferred_username as string)
    }

    automaticallyShowInAppMessages()

    const subscriptionGuid = subscribeToContentCardsUpdates(setCards)

    openSession()

    const cached = getCachedContentCards()

    if (cached.lastUpdated) {
      setCards()
    }

    requestContentCardsRefresh()

    return () => {
      if (subscriptionGuid) {
        removeSubscription(subscriptionGuid)
      }
    }
  }, [isUserLoggedIn, braze, oidcTokens?.decodedIdToken, setCards])

  return brazeBannerContentCards
}
