import React from 'react'
import { cn } from '@vgw/tailwind-merger'

interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {}

export const Icon = ({ className, children, ...props }: IconProps) => {
  return (
    <span
      className={cn(
        'flex h-8 w-8 shrink-0 items-center justify-center rounded-full',
        className,
      )}
      {...props}
    >
      <span className="h-[1.5rem] w-[1.5rem]">{children}</span>
    </span>
  )
}
