import React from 'react'
import ReactDOM from 'react-dom/client'
import '@vgw/multibrand-fe-styles/src/index.css'
import { loadBrandConfig } from '@/config/config'
import { loadCatalog } from '@/i18n'
import { App } from '@/app'
import FlagProvider from '@unleash/proxy-client-react'
import('./themes/styles/styles.css')

const config = await loadBrandConfig()
await loadCatalog('en')

const unleashConfig = config.unleash && {
  url: config.unleash.url,
  clientKey: config.unleash.clientKey,
  appName: config.unleash.appName,
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    {unleashConfig ? (
      <FlagProvider config={unleashConfig}>
        <App amplitudeKey={config.amplitude.apiKey} />
      </FlagProvider>
    ) : (
      <App amplitudeKey={config.amplitude.apiKey} />
    )}
  </React.StrictMode>,
)
