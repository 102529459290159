import { GameTitle } from './game-title'
import { GameConfig } from './game-config/game-config'
import { MoreGames } from './more-games'
import { ReactNode, useContext } from 'react'
import { SelectedCoinTypeContext } from '@/selected-coin-type-context'
import { useGameInfo } from '@/features/game-api/use-game-info'
import { useGameFavorite } from '@/features/game-api/use-game-favorite'
import { Drawer, DrawerContent, DrawerTrigger } from '@vgw/multibrand-fe-drawer'
import { ErrorBoundary } from 'react-error-boundary'
import { Trans } from '@lingui/react/macro'

export interface GameCardProps {
  gameId: string
  triggerComponent: ReactNode
  triggerClassName?: string
}

export const GameInfoDrawer = ({
  gameId,
  triggerComponent,
  triggerClassName,
}: GameCardProps) => {
  return (
    <Drawer>
      <DrawerTrigger asChild className={triggerClassName}>
        {triggerComponent}
      </DrawerTrigger>
      <DrawerContent position="bottom" insetContent={true}>
        {/*todo: error handling*/}
        <ErrorBoundary fallback={<Trans>Something went wrong</Trans>}>
          <GameInfoContent gameId={gameId} />
        </ErrorBoundary>
      </DrawerContent>
    </Drawer>
  )
}

const GameInfoContent = ({ gameId }: { gameId: string }) => {
  const { selectedCoinType } = useContext(SelectedCoinTypeContext)
  const { data, isLoading, error } = useGameInfo(gameId)
  const { toggleFavorite, isLoading: isFavoriteLoading } = useGameFavorite()

  if (isLoading) return null

  if (!data) {
    return null
  }

  if (error) {
    throw error
  }

  const isFavorite = isFavoriteLoading ? !data.isFavorite : data.isFavorite

  const handleToggleFavorite = () =>
    toggleFavorite({
      gameId: data.gameId,
      isFavorite,
    })

  return (
    <div className="flex justify-center self-stretch">
      <div
        data-testid="game-card-content"
        className="flex w-full max-w-screen-2xl flex-col items-start gap-4 p-4 pt-9"
      >
        <GameTitle
          gameCard={data}
          isFavorite={isFavorite}
          handleToggleFavorite={handleToggleFavorite}
        />
        <p className="text-sm leading-5">{data.description}</p>
        <GameConfig
          gameConfig={data.config}
          selectedCoinType={selectedCoinType}
        />
        <hr className="h-[0.0625rem] w-full border-border-muted" />
        <MoreGames games={data.similarGames} />
      </div>
    </div>
  )
}
