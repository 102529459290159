import { Drawer, DrawerContent, DrawerTrigger } from '@vgw/multibrand-fe-drawer'
import {
  CoinTypeProps,
  CoinTypeToggleWrapper,
} from './coin-type-toggle-wrapper'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { UserSidebar } from './user-sidebar/user-sidebar'

export interface UserHeaderProps {
  logout: () => Promise<void>
  username: string | undefined
  userEmail: string | undefined
  coinTypeInfo: CoinTypeProps
}

export const UserHeader = ({
  logout,
  username,
  userEmail,
  coinTypeInfo,
}: UserHeaderProps) => {
  const { amounts } = coinTypeInfo

  return (
    <>
      <CoinTypeToggleWrapper {...coinTypeInfo} />
      <Drawer>
        <DrawerTrigger asChild>
          <IconButton
            icon="person"
            size="md"
            variant="primary"
            data-testid="drawer-button"
          />
        </DrawerTrigger>
        <DrawerContent position="right" className="bg-surface-neutral p-0">
          <UserSidebar
            logout={logout}
            username={username}
            userEmail={userEmail}
            coinAmounts={amounts}
          />
        </DrawerContent>
      </Drawer>
    </>
  )
}
