import { SelectedCoinTypeContextProvider } from '@/selected-coin-type-context'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { getOidc, OidcProvider } from '@/providers/oidc'
import { Tracking } from '@/tracking'
import { ReactQueryProvider } from '@/providers/react-query-povider'
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  RouterProvider,
} from 'react-router-dom'
import Root from '@/layout/root'
import Home from '@/pages/home/home'
import { GAME_CATEGORY_PATH, GAME_PATH, GEO_BLOCK_PATH } from '@/config/paths'
import GamePlay from '@/pages/game-play/game-play'
import { Category } from '@/pages/category'
import ErrorPage from '@/pages/error/error'
import GeoBlock from '@/pages/geo-block/geo-block'
import { Sdd } from '@/pages/account/sdd/sdd'

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Root />,
      children: [
        {
          path: '',
          element: <Home />,
        },
        {
          path: GAME_PATH,
          element: <GamePlay />,
          loader: protectedRouteLoader,
        },
        {
          path: GAME_CATEGORY_PATH,
          element: <Category />,
        },
      ],
      errorElement: <ErrorPage />,
    },
    {
      path: '/',
      element: <Root renderHeader={false} renderBackground />,
      children: [
        {
          path: GEO_BLOCK_PATH,
          element: <GeoBlock />,
        },
      ],
    },
    {
      path: '/',
      element: (
        <Root
          renderHeader={false}
          renderFooter={false}
          mainClassName="bg-surface-tertiary"
        />
      ),
      children: [
        {
          path: '/account/sdd',
          element: <Sdd />,
        },
      ],
    },
  ],
  { basename: import.meta.env.BASE_URL },
)

async function protectedRouteLoader({ request }: LoaderFunctionArgs) {
  const oidc = await getOidc()

  if (oidc.isUserLoggedIn) {
    return null
  }

  await oidc.login({
    // The loader function is invoked by react-router before the browser URL is updated to the target protected route URL.
    // Therefore, we need to specify where the user should be redirected after the login process completes.
    redirectUrl: request.url,

    // Explanation:
    // The 'doesCurrentHrefRequiresAuth' parameter informs oidc-spa whether it is acceptable to redirect the user to the current URL
    // if the user abandons the authentication process. This is crucial to prevent the user from being immediately redirected
    // back to the login page when pressing the back button from the login pages.
    // If the user navigated directly to the protected route (e.g., by clicking a link to your application from an external site),
    // then the current URL requires authentication.
    // Conversely, if the user navigated from an unprotected route within your application to the protected route,
    // then the current URL does not require authentication.
    doesCurrentHrefRequiresAuth: window.location.href === request.url,
  })
  // Never here, the login method redirects the user to the identity provider.
}

export const App = ({ amplitudeKey }: { amplitudeKey: string }) => {
  return (
    <SelectedCoinTypeContextProvider>
      <I18nProvider i18n={i18n}>
        <OidcProvider>
          <Tracking apiKey={amplitudeKey}>
            <ReactQueryProvider>
              <RouterProvider router={router} />
            </ReactQueryProvider>
          </Tracking>
        </OidcProvider>
      </I18nProvider>
    </SelectedCoinTypeContextProvider>
  )
}
