import { cn } from '@vgw/tailwind-merger'
import { Trans } from '@lingui/react/macro'

interface GoldCoinsLabelProps {
  goldCoinsSelected: boolean
  goldCoinsAmountFormatted: string
  className?: string
}

export function GoldCoinsLabel({
  goldCoinsAmountFormatted,
  goldCoinsSelected,
  className,
}: GoldCoinsLabelProps) {
  return (
    <span
      className={cn(
        'bg-surface-neutral flex h-full min-w-64 items-center gap-4 rounded-full border',
        goldCoinsSelected ? 'border-gold-coins-base' : 'border-[transparent]',
        className,
      )}
    >
      <span
        data-testid="gold-coins-label"
        className={cn(
          'text-gold-coins-base',
          !goldCoinsSelected && 'opacity-40',
        )}
      >
        <span className="font-semibold">
          <Trans>GC</Trans>
        </span>{' '}
        {goldCoinsAmountFormatted}
      </span>
    </span>
  )
}
