import { ApiResponse } from '@/api-response'
import { useQuery } from '@tanstack/react-query'
import { getBrandConfig } from '@/config/config'
import { useFetch } from '@/use-fetch'
import { Jackpot as GameTileJackpot } from '@vgw/multibrand-fe-game-tile'

export interface Jackpot {
  id: string
  name: GameTileJackpot['name']
  coinType: string
  value: string
}

export interface Jackpots {
  [key: string]: {
    jackpots: Jackpot[]
  }
}

function useJackpots(): ApiResponse<Jackpots> {
  const config = getBrandConfig()
  const { get } = useFetch()

  const { error, data, isLoading } = useQuery<Jackpots>({
    queryKey: [config.jackpotApi.url],
    queryFn: () => get(config.jackpotApi.url),
    refetchInterval: config.jackpotApi.interval,
  })
  return {
    isLoading,
    error,
    data,
  }
}

export { useJackpots }
