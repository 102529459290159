import { useFetch } from '@/use-fetch'
import { useQuery } from '@tanstack/react-query'
import { getBrandConfig } from '@/config/config'
import { ApiResponse } from '@/api-response'

interface JSONResponse {
  tokens: { tokenType: string; amount: number }[]
}

interface CoinTypeAmounts {
  goldCoinsAmount: number
  sweepsCoinsAmount: number
}

type TokenTypes = 'SC' | 'GC'

function useCoinType(isUserLoggedIn: boolean): ApiResponse<CoinTypeAmounts> {
  const { get } = useFetch()
  const config = getBrandConfig()
  const { isLoading, data, error } = useQuery<JSONResponse>({
    queryKey: ['/tokens', config.tokenApi.url],
    queryFn: () => get(config.tokenApi.url),
    refetchInterval: config.tokenApi.interval,
    enabled: isUserLoggedIn,
  })

  return {
    isLoading,
    data: data && getCoinAmount(data),
    error,
  }
}

function getTokenAmount(tokens: JSONResponse['tokens'], type: TokenTypes) {
  return tokens?.find(({ tokenType }) => tokenType === type)?.amount
}

function getCoinAmount(data: JSONResponse): CoinTypeAmounts {
  return {
    goldCoinsAmount: getTokenAmount(data.tokens, 'GC') || 0,
    sweepsCoinsAmount: getTokenAmount(data.tokens, 'SC') || 0,
  }
}

export { useCoinType }
