import { useState } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'
import {
  JackpotTicker,
  JackpotTickerProps,
} from '@vgw/multibrand-fe-jackpot-ticker'
import { useJackpotPrioritization } from './hooks/use-jackpot-prioritization'
import { GameTag, GameTagProps } from '@vgw/multibrand-fe-game-tag'

const GameTileVariants = cva(
  ['h-full', 'w-full', 'object-cover', 'transition', 'duration-300'],
  {
    variants: {
      variant: {
        square: 'aspect-square',
        round: 'aspect-square rounded-full',
        featured: 'aspect-video',
        portrait: 'aspect-picture',
        landscape: 'aspect-video',
        stackedOne: '',
        stackedTwo: '',
        stackedThree: '',
        stackedFour: '',
      },
    },
    defaultVariants: {
      variant: 'square',
    },
  },
)

const GameTagPositionVariants = cva('absolute isolate z-10 -translate-y-2/4', {
  variants: {
    variant: {
      square: 'left-1',
      round: 'left-4',
      featured: 'left-1',
      portrait: 'left-1',
      landscape: 'left-1',
      stackedOne: 'left-1',
      stackedTwo: 'left-1',
      stackedThree: 'left-1',
      stackedFour: 'left-1',
    },
  },
  defaultVariants: {
    variant: 'square',
  },
})

export interface Jackpot {
  name: NonNullable<JackpotTickerProps['variant']>
  amount: number
}

export interface Tag {
  text: string
  variant: NonNullable<GameTagProps['variant']>
}

interface GameTileProps extends VariantProps<typeof GameTileVariants> {
  imageUrl: string
  name: string
  jackpots?: Jackpot[]
  className?: string
  tag?: Tag
}

export const GameTile = ({
  imageUrl,
  name,
  jackpots = [],
  variant,
  className,
  tag,
}: GameTileProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const jackpotsPrioritized = useJackpotPrioritization({ jackpots })

  return (
    <div
      className={cn(
        ['relative', 'h-auto', 'transition', 'duration-300'],
        {
          'rounded-radius-base': variant !== 'round',
          'rounded-radius-icon-button': variant === 'round',
          'bg-skeleton animate-pulse duration-[2s]': !isImageLoaded,
        },
        className,
      )}
      aria-label={name}
    >
      {!!tag && (
        <GameTag
          text={tag.text}
          variant={tag.variant}
          className={cn(GameTagPositionVariants({ variant }))}
        />
      )}
      <img
        src={imageUrl}
        alt={name}
        className={cn(GameTileVariants({ variant }), {
          'rounded-radius-base': variant !== 'round',
          'rounded-radius-icon-button': variant === 'round',
          'opacity-0': !isImageLoaded,
        })}
        onLoad={() => setIsImageLoaded(true)}
      />
      {jackpotsPrioritized.length > 0 && (
        <div
          className={cn(
            'absolute',
            'flex',
            'w-full',
            'flex-col',
            'gap-1',
            'px-1',
            { 'bottom-1': variant !== 'round' },
            { 'bottom-0.5': variant === 'round' },
          )}
        >
          {jackpotsPrioritized.map((jackpot) => (
            <JackpotTicker
              key={jackpot.name}
              amount={jackpot.amount}
              variant={jackpot.name}
            />
          ))}
        </div>
      )}
    </div>
  )
}
