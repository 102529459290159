import { SddForm } from '@/features/sdd/sdd-form/sdd-form'
import { useOidc } from '@/providers/oidc'
import { useEffect } from 'react'

export const Sdd = () => {
  const { isUserLoggedIn, login } = useOidc()

  useEffect(() => {
    if (!isUserLoggedIn) {
      login({ doesCurrentHrefRequiresAuth: true })
      return
    }
  }, [isUserLoggedIn, login])

  return <SddForm />
}
