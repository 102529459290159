import { Category } from '../category/category'
import { sortByIndex } from './sort-by-index'
import { useContext } from 'react'
import { SkeletonLobby } from './skeleton-lobby'
import { Jackpots } from '@/features/game-api/use-jackpots'
import { LayoutFactory } from '@/features/layout/layout-factory'
import { CoinType, SelectedCoinTypeContext } from '@/selected-coin-type-context'
import { Game, GameCategory } from '@/features/game-api/use-gallery'

interface LobbyProps {
  showLoading: boolean
  categories?: GameCategory[]
  jackpots?: Jackpots
}

export default function Lobby({
  showLoading,
  categories = [],
  jackpots,
}: LobbyProps) {
  const { selectedCoinType } = useContext(SelectedCoinTypeContext)

  if (showLoading) return <SkeletonLobby />

  return (
    <section
      className="flex w-full flex-col gap-8 px-2 py-0 sm:px-4 md:px-6 lg:py-4"
      data-testid="lobby"
    >
      {categories
        .sort(sortByIndex)
        .map(({ name, games, description, iconUrl, variant }) => (
          <Category
            key={name}
            name={name}
            description={description}
            iconUrl={iconUrl}
            layout={
              <LayoutFactory
                variant={variant}
                props={{
                  games: getGamesWithJackPotValue({
                    games,
                    jackpots,
                    selectedCoinType,
                  }),
                }}
              />
            }
          />
        ))}
    </section>
  )
}

const getGamesWithJackPotValue = ({
  games,
  jackpots,
  selectedCoinType,
}: {
  games: Game[]
  jackpots?: Jackpots
  selectedCoinType: CoinType
}) =>
  games
    .map(({ gameId, ...game }) => {
      const jackpotsValues = jackpots?.[gameId]?.jackpots
        ?.filter((jackpot) => jackpot.coinType === selectedCoinType)
        .map((jackpot) => ({
          name: jackpot.name,
          amount: Number(jackpot.value),
        }))

      return {
        ...game,
        gameId,
        jackpots: jackpotsValues || [],
      }
    })
    .sort(sortByIndex)
