import { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { SelectedCoinTypeContext } from '@/selected-coin-type-context'
import { useGameUrl } from '@/pages/game-play/use-game-url'
import { useTracking } from '@vgw/multibrand-fe-tracking'
import { useGameInfo } from '@/features/game-api/use-game-info'

const GamePlay = () => {
  const params = useParams<{
    gameId: string
  }>()

  const { data: gameData } = useGameInfo(params.gameId || '')

  const { selectedCoinType } = useContext(SelectedCoinTypeContext)
  const { getGameUrl, data: gameUrlData } = useGameUrl()
  const { track } = useTracking()

  useEffect(() => {
    if (!gameData) {
      return
    }

    getGameUrl({ launchUrl: gameData.launchUrl, selectedCoinType })
  }, [selectedCoinType, getGameUrl, gameData])

  useEffect(() => {
    return () => {
      if (gameUrlData?.launchUrl) {
        track('GamePlayPage:GameLaunched', {
          eventData: { gameUrl: gameUrlData.launchUrl },
        })
      }
    }
  }, [gameUrlData?.launchUrl, track])

  if (!gameUrlData?.launchUrl) {
    return null
  }

  return (
    <iframe
      title="game"
      className="h-[calc(100vh-64px)] w-full"
      src={gameUrlData.launchUrl}
    ></iframe>
  )
}

export default GamePlay
