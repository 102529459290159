import Lobby from '@/features/lobby/lobby'
import { useGallery } from '@/features/game-api/use-gallery'
import { useJackpots } from '@/features/game-api/use-jackpots'
import { useBrandFeatures } from '@/lib/use-brand-features'
import { LobbyHeroBanner } from '@/features/hero-banner/hero-banner'

const Home = () => {
  const { isLoading, data: gallery } = useGallery()
  const { data: jackpots } = useJackpots()
  const brandFeatures = useBrandFeatures()

  return (
    <div className="w-full py-4 lg:py-0">
      <div className="overflow-x-clip px-4">
        {brandFeatures.isHeroBannerEnabled ? <LobbyHeroBanner /> : null}
      </div>
      <div data-chromatic="ignore">
        <Lobby
          showLoading={isLoading}
          categories={gallery?.categories}
          jackpots={jackpots}
        />
      </div>
    </div>
  )
}

export default Home
