import { cn } from '@vgw/tailwind-merger'

export const SkeletonHeroBanner = () => {
  const numCards = calcNumCards(Math.min(window.innerWidth, 1536) / 16)

  return (
    <div
      className="mb-4 mt-2 flex justify-between gap-4 lg:mt-6"
      data-testid="hero-banner-loader"
    >
      {[...Array(numCards)].map((_, idx) => (
        <div
          key={`carousel-card-${idx}`}
          className={cn(
            'aspect-[16/9] w-full max-w-[500px]',
            'animate-pulse',
            'bg-skeleton',
          )}
        />
      ))}
    </div>
  )
}

const calcNumCards = (viewPortWidthInRem: number) => {
  let numCards
  switch (true) {
    case viewPortWidthInRem <= 30:
      numCards = 1
      break
    case viewPortWidthInRem > 30 && viewPortWidthInRem <= 48:
      numCards = 2
      break
    case viewPortWidthInRem > 48:
      numCards = 3
      break
    default:
      numCards = 3
  }
  return numCards
}
