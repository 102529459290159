import { createContext } from 'react'

interface ContextValue {
  size: 'sm' | 'md'
  isDefaultCaptionOpen: boolean
  inactiveYears: Set<number>
  initialYear: number
  setIsDefaultCaptionOpen?: (_: boolean) => void
}

export const CalendarContext = createContext<ContextValue>({
  size: 'sm',
  isDefaultCaptionOpen: false,
  inactiveYears: new Set<number>([]),
  initialYear: new Date().getFullYear() - 30,
})
