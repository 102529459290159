import { useParams } from 'react-router-dom'
import { useCategory } from '@/pages/category/use-category'
import { LinkGameTile } from '@/features/game-card/link-game-tile'
import { Trans } from '@lingui/react/macro'

function Category() {
  const { categoryName } = useParams<{ categoryName: string }>()
  const { isLoading, category, error } = useCategory(categoryName || '')

  if (isLoading)
    return (
      <span>
        <Trans>Loading</Trans>
      </span>
    )

  if (error)
    return (
      <p>
        <Trans>An error has occurred</Trans>
      </p>
    )

  return (
    <section className="flex min-h-dvh flex-col items-start gap-2 px-2 py-0 sm:px-4 md:px-6 lg:py-4">
      <header className="font-display text-xl font-bold text-category-title md:text-2xl">
        {category?.name}
      </header>
      <div className="align-start flex flex-wrap items-start gap-2 self-stretch">
        {category?.games.map((game) => (
          <LinkGameTile
            key={game.gameId}
            game={game}
            variant={category.variant}
          />
        ))}
      </div>
    </section>
  )
}

export { Category }
