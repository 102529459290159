import { useEffect } from 'react'
import { useLingui } from '@lingui/react/macro'

interface SimpleHeroBannerCard {
  src: string
  title: string
  url?: string
  sizes?: string
  srcSet?: string
  onCardClick?: () => void
}

interface SimpleHeroBannerProps {
  card: SimpleHeroBannerCard
  onCardEntersViewport?: (cardIndex: number) => void
}

export const SimpleHeroBanner = ({
  card,
  onCardEntersViewport,
}: SimpleHeroBannerProps) => {
  const { t } = useLingui()

  useEffect(() => {
    onCardEntersViewport?.(0)
  }, [onCardEntersViewport])

  const Img = () => (
    <img
      alt={card.url ? t`Navigate to ${card.title}` : card.title}
      src={card.src}
      sizes={card.sizes}
      srcSet={card.srcSet}
      className="aspect-video h-full w-full object-cover"
    />
  )

  return card.url ? (
    <a
      href={card.url}
      target="_blank"
      rel="noreferrer"
      onClick={() => card.onCardClick?.()}
      className="w-full cursor-pointer"
    >
      {Img()}
    </a>
  ) : (
    Img()
  )
}
