import { cn } from '@vgw/tailwind-merger'
import { Trans } from '@lingui/react/macro'

interface SweepsCoinsLabelsProps {
  sweepsCoinsSelected: boolean
  sweepsCoinsAmountFormatted: string
  className?: string
}

export function SweepsCoinsLabel({
  className,
  sweepsCoinsAmountFormatted,
  sweepsCoinsSelected,
}: SweepsCoinsLabelsProps) {
  return (
    <span
      className={cn(
        'bg-surface-neutral flex h-full min-w-64 items-center gap-4 rounded-full border',
        sweepsCoinsSelected
          ? 'border-sweeps-coins-base'
          : 'border-[transparent]',
        className,
      )}
    >
      <span
        data-testid="sweeps-coins-label"
        className={cn(
          'text-sweeps-coins-base',
          !sweepsCoinsSelected && 'opacity-40',
        )}
      >
        <span className="font-semibold">
          <Trans>SC</Trans>
        </span>{' '}
        {sweepsCoinsAmountFormatted}
      </span>
    </span>
  )
}
