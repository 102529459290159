import { Game } from '@/features/game-api/use-gallery'
import { LayoutSquare } from '@/features/layout/components/layout-square'
import { LayoutPortrait } from '@/features/layout/components/layout-portrait'
import { LayoutRound } from '@/features/layout/components/layout-round'
import { LayoutLandscape } from '@/features/layout/components/layout-landscape'
import { LayoutStackedOne } from '@/features/layout/components/layout-stacked-one'
import { LayoutStackedTwo } from '@/features/layout/components/layout-stacked-two'
import { LayoutStackedThree } from '@/features/layout/components/layout-stacked-three'
import { LayoutStackedFour } from '@/features/layout/components/layout-stacked-four'
import { Jackpot } from '@vgw/multibrand-fe-game-tile'

export interface LayoutProps {
  games: (Game & { jackpots: Jackpot[] })[]
}

const Layouts = {
  square: LayoutSquare,
  portrait: LayoutPortrait,
  landscape: LayoutLandscape,
  round: LayoutRound,
  stackedOne: LayoutStackedOne,
  stackedTwo: LayoutStackedTwo,
  stackedThree: LayoutStackedThree,
  stackedFour: LayoutStackedFour,
}

type LayoutVariant = keyof typeof Layouts

export interface LayoutFactoryProps {
  props: LayoutProps
  variant?: LayoutVariant
}

const LayoutFactory = ({ variant = 'square', props }: LayoutFactoryProps) => {
  const Comp = Layouts[variant]

  return <Comp {...props} />
}

export { LayoutFactory, type LayoutVariant }
