import { useEffect, useRef, useState } from 'react'
import { format as formatDate } from 'date-fns'
import { TextField } from '@vgw/multibrand-fe-text-field'
import { Calendar } from '@vgw/multibrand-fe-calendar'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { useLingui } from '@lingui/react/macro'

interface DatePickerProps {
  selectedDate?: Date
  setSelectedDate: (date: Date) => void
  format?: string
  label: string
  htmlFor?: string
  error?: boolean
}

export const DatePicker = ({
  selectedDate,
  setSelectedDate,
  // eslint-disable-next-line lingui/no-unlocalized-strings
  format = 'MM/dd/yyyy',
  label,
  htmlFor,
  error = false,
}: DatePickerProps) => {
  const { t } = useLingui()
  const dialogRef = useRef<HTMLDialogElement>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [value, setValue] = useState(
    selectedDate ? formatDate(selectedDate.toString(), format) : undefined,
  )

  useEffect(() => {
    const handleBodyScroll = (isOpen: boolean) => {
      document.body.style.overflow = isOpen ? 'hidden' : ''
    }
    if (!dialogRef.current) return
    if (isDialogOpen) {
      handleBodyScroll(true)
      dialogRef.current.showModal()
    } else {
      handleBodyScroll(false)
      dialogRef.current.close()
    }

    return () => {
      handleBodyScroll(false)
    }
  }, [isDialogOpen])

  return (
    <div className="flex flex-col gap-4">
      <div className="relative text-center text-xl text-[#fff]">
        <TextField
          endIcon={
            <IconButton
              variant="ghost"
              type="button"
              icon="calendar"
              aria-controls="dialog"
              aria-haspopup="dialog"
              size="sm"
              aria-expanded={isDialogOpen}
              aria-label={t`Open calendar to choose booking date`}
              onClick={() => {
                setIsDialogOpen((value) => !value)
              }}
            />
          }
          onClick={() => {
            if (isDialogOpen) {
              setIsDialogOpen(false)
            }
          }}
          value={value}
          error={error}
        >
          <TextField.Label htmlFor={htmlFor || ''}>{label}</TextField.Label>
          <TextField.MaskedInput
            data-testid="date-picker-input"
            mask={format?.replace(/[a-zA-Z]/g, '9')}
            onChange={(value) => {
              setValue(value.target.value)
              const timeStamp = Date.parse(value.target.value)

              if (!isNaN(timeStamp)) {
                setSelectedDate(new Date(timeStamp))
              }
            }}
            autoComplete="bday"
          />
        </TextField>
        {isDialogOpen && (
          <div className="absolute left-0 top-full z-50 mt-2 border border-gray-300 bg-white shadow-lg">
            <Calendar
              mode="single"
              selected={selectedDate}
              onSelect={(date) => {
                setSelectedDate(date ?? new Date())
                setValue(formatDate(date ?? new Date(), format))
                setIsDialogOpen(false)
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
