import { CoinTypeToggle, CoinType } from '@vgw/multibrand-fe-coin-type-toggle'
import { useLocation } from 'react-router-dom'
import { CoinTypeAmounts } from '../types'
import { Trans } from '@lingui/react/macro'

export interface CoinTypeProps {
  isLoading: boolean
  error: Error | null
  amounts?: CoinTypeAmounts
  selectedCoinType: CoinType
  onCoinTypeChange: () => void
  gamePlayPath: string
  isSweepsCoinsEnabled: boolean
}

function CoinTypeToggleWrapper({
  amounts,
  isLoading,
  error,
  selectedCoinType,
  onCoinTypeChange,
  gamePlayPath,
  isSweepsCoinsEnabled,
}: CoinTypeProps) {
  const { pathname } = useLocation()

  if (isLoading) {
    return (
      <div className="text-[#ffffff]">
        <Trans>Getting token data...</Trans>
      </div>
    )
  }

  if (error) {
    return (
      <div className="text-[#ffffff]">
        <Trans>Error: Could not retrieve token data</Trans>
      </div>
    )
  }

  return (
    <CoinTypeToggle
      onToggle={onCoinTypeChange}
      selectedCoinType={selectedCoinType}
      goldCoinsAmount={amounts?.goldCoinsAmount}
      sweepsCoinsAmount={amounts?.sweepsCoinsAmount}
      disabled={!isSweepsCoinsEnabled || pathname.includes(gamePlayPath)}
    />
  )
}

export { CoinTypeToggleWrapper }
