import { SimpleHeroBanner } from '@vgw/multibrand-fe-simple-hero-banner'
import { CarouselHeroBanner } from '@vgw/multibrand-fe-carousel-hero-banner'
import { ErrorBoundary } from 'react-error-boundary'
import { DefaultHeroBanner } from './default-hero-banner'
import { SkeletonHeroBanner } from './skeleton-hero-banner'
import { Slides, useBrazeHeroBanner } from './use-braze-hero-banner'
import { getBrandConfig } from '@/config/config'
import { logCardClick, logContentCardImpressions } from '@braze/web-sdk'
import { OverlayedHeroBanner } from '@vgw/multibrand-fe-overlayed-hero-banner'
import { useCallback } from 'react'

export const LobbyHeroBanner = () => {
  return (
    <ErrorBoundary fallback={<DefaultHeroBanner />}>
      <LobbyHeroBannerContent />
    </ErrorBoundary>
  )
}

const LobbyHeroBannerContent = () => {
  const { isLoading, slides } = useBrazeHeroBanner()
  const brandConfig = getBrandConfig()

  const onCardEntersViewport = useCallback(
    (index: number) => {
      const slide = slides.at(index)
      if (!slide) return
      logContentCardImpressions([slide.card])
    },
    [slides],
  )

  if (isLoading) {
    return <SkeletonHeroBanner />
  }

  if (slides.length === 0) {
    return
  }

  if (slides.length === 1) {
    const cards = convertSlidesToCards(slides)

    return (
      <SimpleHeroBanner
        card={cards[0]}
        onCardEntersViewport={onCardEntersViewport}
      />
    )
  }

  if (brandConfig.heroBanner.type === 'carouselOverlayed') {
    const cards = convertSlidesToCards(slides)

    return (
      <OverlayedHeroBanner
        cards={cards}
        onCardEntersViewport={onCardEntersViewport}
      />
    )
  }

  if (
    brandConfig.heroBanner.type === 'carouselWithLeadingCard' ||
    brandConfig.heroBanner.type === 'carouselBasic'
  ) {
    const cards = convertSlidesToCards(slides)

    return (
      <div data-chromatic="ignore">
        <CarouselHeroBanner
          cards={cards}
          variant={
            brandConfig.heroBanner.type === 'carouselWithLeadingCard'
              ? 'leadingCard'
              : 'basic'
          }
        />
      </div>
    )
  }
}

const convertSlidesToCards = (slides: Slides[]) => {
  return slides.map((slide) => {
    const mobileImageUrl =
      slide.extras.mobile_image_url || slide.extras.desktop_image_url

    return {
      src: mobileImageUrl,
      title: '',
      url: slide.card.url,
      srcSet: `
          ${mobileImageUrl} 480w,
          ${slide.extras.desktop_image_url} 1024w,
        `,
      onCardClick: () => {
        logCardClick(slide.card)
      },
      thumbnailSrc:
        slide.extras.thumbnail_image_url || slide.extras.desktop_image_url,
    }
  })
}
