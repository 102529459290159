import {
  Carousel,
  useDotButton,
  usePrevNextButtons,
  WheelGesturesPlugin,
  AutoPlay,
} from '@vgw/multibrand-fe-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import { type ClassValue } from 'clsx'
import { cn } from '@vgw/tailwind-merger'
import { useLingui } from '@lingui/react/macro'
import { NavigationArrows } from './navigation-arrows'
import { cva, VariantProps } from 'class-variance-authority'

interface CarouselHeroBannerCard {
  src: string
  title: string
  url?: string
  sizes?: string
  srcSet?: string
  onCardClick?: () => void
}

interface CarouselHeroBannerProps extends VariantProps<typeof variants> {
  cards: CarouselHeroBannerCard[]
  /**
   * Set the autoplay speed. Set to false if you want to disable autoplay completely.
   */
  autoPlayDelay?: number | false
}

const variants = cva(['w-full'], {
  variants: {
    variant: {
      basic: '',
      leadingCard: ['duration-600 transition-all ease-in-out'],
    },
  },
  defaultVariants: {
    variant: 'basic',
  },
})

export const CarouselHeroBanner = ({
  cards,
  autoPlayDelay = 7000,
  variant,
}: CarouselHeroBannerProps) => {
  const { t } = useLingui()
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
    },
    [
      WheelGesturesPlugin(),
      ...(autoPlayDelay !== false
        ? [
            AutoPlay({
              rootNode: (emblaRoot: HTMLElement) =>
                emblaRoot.closest('.js-carousel-container'),
              playOnInit: true,
              delay: autoPlayDelay,
              stopOnMouseEnter: true,
              stopOnInteraction: false,
            }),
          ]
        : []),
    ],
  )

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi)
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const isSingleCard = cards.length === 1

  const heightClasses = (index: number) => ({
    'h-full': variant === 'basic' || selectedIndex === index,
    'h-[90%]': variant === 'leadingCard' && selectedIndex !== index,
  })

  const Img = ({
    card,
    classNames,
  }: {
    card: CarouselHeroBannerCard
    classNames: ClassValue[]
  }) => (
    <img
      alt={card.url ? t`Navigate to ${card.title}` : card.title}
      src={card.src}
      sizes={card.sizes}
      srcSet={card.srcSet}
      className={cn(
        'rounded-radius-base aspect-video w-full object-cover',
        ...classNames,
      )}
    />
  )

  return (
    <Carousel
      data-testid="carousel-hero-banner"
      className="js-carousel-container flex flex-col gap-2"
    >
      <NavigationArrows
        leftNavigationDisabled={prevBtnDisabled}
        onLeftNavigation={onPrevButtonClick}
        rightNavigationDisabled={nextBtnDisabled}
        onRightNavigation={onNextButtonClick}
        isSingleCard={isSingleCard}
      />
      <Carousel.Container ref={emblaRef} className="flex flex-col gap-3">
        <Carousel.CardContainer>
          {cards.map((card, index) => (
            <Carousel.Card
              key={index}
              index={index}
              className={cn(
                'flex w-[96%] items-center px-1 xl:w-[80%] 2xl:w-[70%]',
                {
                  'w-full': isSingleCard,
                },
              )}
              data-testid={`card-${index}`}
            >
              {card.url ? (
                <a
                  href={card.url}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => card.onCardClick?.()}
                  className={cn(variants({ variant }), heightClasses(index))}
                >
                  {Img({ card, classNames: ['h-full'] })}
                </a>
              ) : (
                Img({
                  card,
                  classNames: [variants({ variant }), heightClasses(index)],
                })
              )}
            </Carousel.Card>
          ))}
        </Carousel.CardContainer>
        <Carousel.IndicatorsContainer data-testid="indicators-container">
          {scrollSnaps.map((_, index) => (
            <Carousel.Indicator
              key={`indicator-${index}`}
              data-testid={`indicator-${index}`}
              isActive={selectedIndex === index}
              onClick={() => onDotButtonClick(index)}
            />
          ))}
        </Carousel.IndicatorsContainer>
      </Carousel.Container>
    </Carousel>
  )
}
