import { Root } from '@radix-ui/react-toggle'
import { cn } from '@vgw/tailwind-merger'
import { SweepsCoinsLabel } from './sweeps-coins-label'
import { CoinTypeToggleIcons } from './coin-type-toggle-icons'
import { GoldCoinsLabel } from './gold-coins-label'

export type CoinType = 'GC' | 'SC'

export interface CoinTypeToggleProps {
  selectedCoinType: CoinType
  onToggle: () => void
  disabled?: boolean
  goldCoinsAmount?: number
  sweepsCoinsAmount?: number
}

const formatGCAmount = (amount: number) => {
  return formatAmount(amount, 0)
}

const formatSCAmount = (amount: number) => {
  const unitAmount = amount / 100
  return formatAmount(unitAmount, 2)
}

const formatAmount = (amount: number, decimals: number = 0) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(amount)
}

const CoinTypeToggle = ({
  selectedCoinType,
  onToggle,
  goldCoinsAmount = 0,
  sweepsCoinsAmount = 0,
  disabled = false,
}: CoinTypeToggleProps) => {
  const goldCoinsSelected = selectedCoinType === 'GC'
  const sweepsCoinsSelected = selectedCoinType === 'SC'

  return (
    <div className="@container flex w-full items-center justify-center">
      <Root
        data-testid="coin-type-toggle"
        pressed={goldCoinsSelected}
        onPressedChange={!disabled ? onToggle : () => {}}
        className="flex h-8"
        disabled={disabled}
      >
        <CoinTypeToggleIcons
          disabled={disabled}
          goldCoinsSelected={goldCoinsSelected}
          sweepsCoinsSelected={sweepsCoinsSelected}
          className="@xl:order-2 @xl:ml-[-1.625rem] @xl:mr-[-1.625rem] mr-[-4.25rem]"
        />
        <GoldCoinsLabel
          goldCoinsSelected={goldCoinsSelected}
          goldCoinsAmountFormatted={formatGCAmount(goldCoinsAmount)}
          className={cn(
            '@xl:order-1 @xl:justify-end @xl:pl-0 @xl:pr-[2.625rem] pl-[5.25rem]',
            !goldCoinsSelected && '@xl:flex hidden',
          )}
        />
        <SweepsCoinsLabel
          sweepsCoinsSelected={sweepsCoinsSelected}
          sweepsCoinsAmountFormatted={formatSCAmount(sweepsCoinsAmount)}
          className={cn(
            '@xl:order-3 @xl:pl-[2.625rem] pl-[5.25rem]',
            !sweepsCoinsSelected && '@xl:flex hidden',
          )}
        />
      </Root>
    </div>
  )
}

export { CoinTypeToggle }
