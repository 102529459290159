import { useBraze } from '@/features/braze/use-braze'
import { useMemo } from 'react'

import * as z from 'zod'
import { ImageOnly } from '@braze/web-sdk'

const BrazeHeroBannerExtrasSchema = z.object({
  page: z.string().optional(),
  /**
   * In case different hero banners are assigned to the same player,
   * this would default to the one with the highest priority
   *
   * A number from "-100" to "100"
   */
  priority: z.coerce.number().optional().default(0),
  /**
   * Currently we only have one placement, at the top of the Game gallery,
   * hence this is not really necessary.
   * However, in case we have more than one placement in a page this can become
   *
   * Should always be 'hero_banner' for now, until we don't have other options
   */
  placement: z.string(),
  /**
   * Url for desktop image, dimensions TBA
   */
  desktop_image_url: z.string(),
  /**
   * Url for mobile image, dimensions TBA
   */
  mobile_image_url: z.string().optional(),
  /**
   * (Optional) Url for thumbnail image, if Carousel with thumbnails is used
   */
  thumbnail_image_url: z.string().optional(),
})

export type BrazeHeroBannerExtras = z.infer<typeof BrazeHeroBannerExtrasSchema>
export interface Slides {
  card: ImageOnly
  extras: BrazeHeroBannerExtras
}

export const useBrazeHeroBanner = () => {
  const { isLoading, cards } = useBraze()

  const slides = useMemo(() => {
    if (!cards?.length) {
      return []
    }

    const slides: Slides[] = []

    for (const card of cards) {
      const extras = BrazeHeroBannerExtrasSchema.safeParse(card.extras)

      if (extras.error) {
        console.error(
          'Invalid KeyValue pairs for Hero Banner slide. Braze card id: ' +
            card.id,
          extras.error,
        )
        continue
      }

      if (!(card instanceof ImageOnly)) {
        console.error(
          'Herro banner cards could be ImageOnly. Please Select a different type. in a Braze Admin',
        )
        continue
      }

      slides.push({
        card: card,
        extras: extras.data,
      })
    }

    return slides
      .filter((slide) => slide.extras.placement === 'hero_banner')
      .sort((a, b) => b.extras.priority - a.extras.priority)
      .splice(0, 4)
  }, [cards])

  return { slides, isLoading }
}
