import { cn } from '@vgw/tailwind-merger'
import { Icon } from './icon'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import { useLingui } from '@lingui/react/macro'

const getBackgroundColor = (goldCoinsSelected: boolean, disabled: boolean) => {
  if (disabled) {
    return goldCoinsSelected ? '@xl:bg-surface-neutral' : 'bg-surface-neutral'
  }
  return goldCoinsSelected ? 'bg-gold-coins-base' : 'bg-sweeps-coins-base'
}

interface CoinTypeToggleIconsProps {
  disabled: boolean
  goldCoinsSelected: boolean
  sweepsCoinsSelected: boolean
  className?: string
}

export function CoinTypeToggleIcons({
  disabled,
  goldCoinsSelected,
  sweepsCoinsSelected,
  className,
}: CoinTypeToggleIconsProps) {
  const { t } = useLingui()

  return (
    <span
      className={cn(
        'z-10 flex w-[4.25rem] gap-1 rounded-full',
        getBackgroundColor(goldCoinsSelected, disabled),
        className,
      )}
    >
      <Icon
        className={
          goldCoinsSelected ? 'bg-gold-coins-surface-active' : 'opacity-40'
        }
        data-testid="coloured:gold-coin"
        data-selected={goldCoinsSelected}
      >
        <CustomIcon name="coloured:gold-coin" size="lg" title={t`Gold coins`} />
      </Icon>
      <Icon
        className={
          sweepsCoinsSelected ? 'bg-sweeps-coins-surface-active' : 'opacity-40'
        }
        data-testid="coloured:sweeps-coin"
        data-selected={sweepsCoinsSelected}
      >
        <CustomIcon
          name="coloured:sweeps-coin"
          size="lg"
          title={t`Sweeps coins`}
        />
      </Icon>
    </span>
  )
}
