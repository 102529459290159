import { BrandFeatures, getBrandConfig } from '@/config/config'
import { useGallery } from '@/features/game-api/use-gallery'

export const useBrandFeatures = (): BrandFeatures => {
  const { data: gallery } = useGallery()
  const brandConfig = getBrandConfig()

  return {
    ...brandConfig.features,
    isSweepsCoinsEnabled: gallery?.coinMode === 'GC_SC',
  }
}
