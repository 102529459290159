import z from 'zod'

const schema = z.object({
  CLIENT_VAR__BRAND: z
    .union([z.literal('vanilla'), z.literal('chocolate')])
    .default('vanilla'),
  CLIENT_VAR__APP_ENV: z
    .union([
      z.literal('local'),
      z.literal('dev'),
      z.literal('test'),
      z.literal('production'),
    ])
    .default('local'),
})

export const env = schema.parse({
  CLIENT_VAR__BRAND: import.meta.env.CLIENT_VAR__BRAND,
  CLIENT_VAR__APP_ENV: import.meta.env.CLIENT_VAR__APP_ENV,
})
