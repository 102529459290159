import { GameTile } from '@vgw/multibrand-fe-game-tile'
import { Link } from 'react-router-dom'
import { GAME_PLAY_PATH } from '@/config/paths'
import { Game, GameCategory } from '@/features/game-api/use-gallery'
import { useGameThumbnail } from './use-game-thumbnail/use-game-thumbnail'

interface LinkGameTileProps {
  game: Game
  variant: GameCategory['variant']
}

export const LinkGameTile = ({ game, variant }: LinkGameTileProps) => {
  const thumbnailUrl = useGameThumbnail({ game, variant })

  return (
    <Link
      data-testid={`game-${game.gameId}`}
      key={game.gameId}
      to={`${GAME_PLAY_PATH}/${game.gameId}`}
      state={{ launchUrl: game.launchUrl }}
      className="h-auto max-w-[115px] md:max-w-[148px] lg:max-w-[172px] xl:max-w-[216px]"
    >
      <GameTile name={game.name} variant={variant} imageUrl={thumbnailUrl} />
    </Link>
  )
}
