interface Indexed {
  index: number
}

const sortByIndex = (a: Indexed, b: Indexed) => {
  if (a.index < b.index) {
    return -1
  } else if (a.index > b.index) {
    return 1
  }

  return 0
}

export { sortByIndex }
