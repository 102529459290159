import { Label } from './label'
import { Trans } from '@lingui/react/macro'

interface PlayerReturnProps {
  amount: number
}

function PlayerReturn({ amount }: PlayerReturnProps) {
  return (
    <>
      <p className="bg-gradient-to-r from-[#00FFA3] to-[#5FB2FF] bg-clip-text text-lg font-bold leading-7 tracking-[-0.0625rem] text-transparent">
        {amount}
        <span className="text-xs font-bold leading-4">%</span>
      </p>
      <Label>
        <Trans>Return to player</Trans>
      </Label>
    </>
  )
}

export { PlayerReturn }
