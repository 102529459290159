import { cn } from '@vgw/tailwind-merger'
import { Primitive, PrimitivePropsWithRef } from '@radix-ui/react-primitive'
import { forwardRef } from 'react'

export interface LinkProps extends PrimitivePropsWithRef<'a'> {}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ href, children, className, ...props }, ref) => {
    return (
      <Primitive.a
        ref={ref}
        href={href}
        className={cn(
          'text-link-primary hover:text-link-primary-hover active:text-link-primary-active underline',
          className,
        )}
        {...props}
      >
        {children}
      </Primitive.a>
    )
  },
)

Link.displayName = 'Link'

export { Link }
