import { Game, GameCategory } from '@/features/game-api/use-gallery'
import { useMemo } from 'react'

export interface UseGameThumbnailProps {
  game: Game
  variant: GameCategory['variant']
}

export const useGameThumbnail = ({ game, variant }: UseGameThumbnailProps) => {
  const { thumbnails } = game

  return useMemo(() => {
    const variantMap: Record<GameCategory['variant'], string> = {
      square: thumbnails.image1x1,
      portrait: thumbnails.image2x3,
      landscape: thumbnails.image16x9,
    }

    return variantMap[variant] || thumbnails.image16x9
  }, [thumbnails, variant])
}
