import { CoinTypeAmounts } from '../../types'
import { UserSidebarInformation } from './user-sidebar-information'
import { UserSidebarNavigation } from './user-sidebar-navigation'
import { Trans } from '@lingui/react/macro'

export interface UserSidebarProps {
  logout: () => Promise<void>
  username: string | undefined
  userEmail: string | undefined
  coinAmounts: CoinTypeAmounts | undefined
}

export const UserSidebar = ({
  logout,
  username,
  userEmail,
  coinAmounts,
}: UserSidebarProps) => {
  return (
    <aside
      className="h-full w-full"
      data-testid="page-header-drawer"
      aria-labelledby="navmenulabel"
    >
      <h2 id="navmenulabel" className="sr-only">
        <Trans>Navigation</Trans>
      </h2>
      <UserSidebarInformation
        userName={username}
        userEmail={userEmail}
        coinAmounts={coinAmounts}
      />
      <UserSidebarNavigation logout={logout} />
    </aside>
  )
}
